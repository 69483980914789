/* Pricing */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --xmd-viewport (min-width: 640px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-pricing-table{
  display: flex;
  flex-direction: column;
  padding: var(--RS-space-64) 0;
  /* font-size: var(--RS-font-size-h3-desktop); */
  
  @media (--lg-viewport) {
  }
}

.RS-pricing-table__heading,
.RS-pricing-table__content,
.RS-pricing-table__buttons{
    display: flex;
    position: relative;
}


.RS-pricing-table__heading:after,
.RS-pricing-table__content:not(:nth-of-type(4)):after{
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  bottom: var(--RS-space-2-n);
  background-color: #fff;
  height: var(--RS-space-2);
  box-shadow: var(--RS-box-shadow);
}

.RS-pricing-table__content > div:first-of-type{
  display: flex;
  flex-direction: column;
}


.RS-pricing-table__heading > div,
.RS-pricing-table__content > div,
.RS-pricing-table__buttons > div{
  display: flex;
  padding: var(--RS-space-16) 0;
  justify-content: center;
  align-items: center;

  @media (--lg-viewport) {
    padding: var(--RS-space-24);
  }
}

.RS-pricing-table__heading > div:first-of-type,
.RS-pricing-table__content > div:first-of-type,
.RS-pricing-table__buttons > div:first-of-type{
  width: 25%;
  justify-content: flex-start;
  align-items: flex-start;

  @media (--sm-viewport) {
    width: 30%;
  }
}

.RS-pricing-table__heading > div:not(:first-of-type),
.RS-pricing-table__content > div:not(:first-of-type),
.RS-pricing-table__buttons > div:not(:first-of-type){
  width: 25%;

  @media (--sm-viewport) {
    width: 23.33%;
  }
}

.RS-pricing-table__heading > div:not(:first-of-type){
  position: relative;
}

.RS-pricing-table__heading > div:not(:first-of-type):before{
  content: '';
  position: absolute;
  width: 80%;
  left: 10%;
  height: 100%;
  bottom: 0;
  border-radius: var(--RS-space-16) var(--RS-space-16) 0 0;
  z-index: -1;

  @media (--sm-viewport) {
    background-color: #fff;
  }
}

.RS-pricing-table__check{
  background-color: var(--RS-black-color);
  border-radius: 50%;
  width: var(--RS-space-24);
  height: var(--RS-space-24);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--lg-viewport) {
    width: var(--RS-space-48);
    height: var(--RS-space-48);
  }
}


.RS-pricing-table__icon{
  width: var(--RS-space-12);
  height: var(--RS-space-12);;

  @media (--lg-viewport) {
    width: var(--RS-space-16);
    height: var(--RS-space-16);
  }
}

.RS-pricing-table__heading,
.RS-pricing-table__content > div > span:first-of-type{
  font-size: var(--RS-font-size-label);
  font-weight: var(--RS-font-weight-variable-bold);

  @media (--sm-viewport) {
    font-size: var(--RS-font-size-h3);
  }

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-h3-desktop);
  }
}

.RS-pricing-table__content > div > span:last-of-type{
  margin-top: var(--RS-space-8);
  font-size: var(--RS-font-size-label);
  font-weight: var(--RS-font-weight-variable-medium);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-label-desktop);
  }
}

.RS-pricing-table__buttons .RS-button{
  padding: var(--RS-space-16) var(--RS-space-24);
  margin-top: var(--RS-space-8);
}

.RS-pricing-table__buttons .RS-button{
  font-size: var( --RS-font-size-label);
  text-align: center;
  max-width: calc(100% - var(--RS-space-8));
  padding: var(--RS-space-6) var(--RS-space-6);

  @media (--sm-viewport) {
    padding: var(--RS-space-12) var(--RS-space-6);
  }

  @media (--lg-viewport) {
    font-size: inherit;
  }
}