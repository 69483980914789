/* Tile */

/* 
Todo: Parcel as of now does not import @custom-media rules from any other files and fails to build if @custom-media is not defined explicitly in every CSS file that makes use of it, the below is a test to confirm, other rules get imported
@import url("custom-media.css");
 */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --xmd-viewport (min-width: 640px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-intro__tile-wrapper{
  display: flex;
  flex-direction: column-reverse;
  /* align-items: flex-end; */
  justify-content: flex-end;
  gap: var(--RS-space-32);
  width: 100%;
  margin-top: var(--RS-space-96);

  @media (--lg-viewport) {
    flex-direction: row;
    gap: unset;
    margin-top: auto;
    margin-bottom: calc(var(--RS-space-32) + var(--RS-space-8));
  }
}

.RS-tile-slider{
  position: relative;

  /* transform: scale(.6); */

  @media (--lg-viewport) {
    transform: none;
  }
}

.RS-intro__tile-slider-group{
  display: none;
  /* opacity: 0; */
  transition: opacity var(--RS-anim-duration-md) linear, transform var(--RS-anim-duration-md) var(--RS-default-transition);

  justify-content: flex-start;
  flex-direction: row-reverse;

  position: absolute;
  /* right: -120px; */

  @media (--xmd-viewport) {
    /* flex-direction: column-reverse; */
  }

  @media (--lg-viewport) {
    position: relative;
    justify-content: unset;
    flex-direction: row;
    margin-top: unset;
    right: unset;
  }
}

.RS-intro__tile-slider-group:first-of-type{
  display: flex;
  opacity: 1;
}

.RS-tile {
  width: calc(var(--RS-space-256) + var(--RS-space-64));
  height: calc(var(--RS-space-256) + var(--RS-space-64));
  border-radius: calc(var(--RS-space-8) + var(--RS-space-2));
  display: flex;
  background-color: var(--RS-light-grey-color);
  box-shadow: var(--RS-box-shadow-alt);
  transform: translateX(var(--RS-space-16));
  overflow: hidden;
  position: relative;
  
  transition: opacity .75s linear, transform .75s var(--RS-default-transition);

  @media (--md-viewport) {
  }
}



.RS-intro__tile-slider-group .RS-tile:nth-of-type(2){
  z-index: -1;
  box-shadow: unset;
  transform: translateX(var(--RS-space-16-n));
  margin-right: var(--RS-space-128-n);
  margin-top: var(--RS-space-48-n);

  @media (--xmd-viewport) {
    margin-right: var(--RS-space-48-n);
  }

  @media (--lg-viewport) {
    position: absolute;
    top: -20%;
    left: -84%;
    margin: unset;
  }
}

.RS-tile-group.RS-is-visible .RS-tile{
  transform: none;
}

.RS-tile--cv-dog{
  background-color: #e3d6d3;
}

.RS-tile--nlp-files:before{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 150%;
  height: 150%;
  background-image: radial-gradient(ellipse at left top, var(--RS-nlp-color) 0% 34%, transparent 100%);
}

.RS-tile--nlp-reviews{
  background-image: radial-gradient(ellipse at left top, rgba(196,45,25,.34) 0%, transparent 50% 100%);
}

.RS-tile--sd-recommendation:before{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 150%;
  height: 150%;
  background-image: radial-gradient(ellipse at left bottom, var(--RS-sd-color) 0% 34%, transparent 100%), url('../../images/bg-json.svg');
  background-size: 150%;
  background-repeat: no-repeat;
  /* background-blend-mode: screen; */
}

.RS-tile--sd-churn{
  background-image: radial-gradient(circle at 0%, rgba(154,62,177,.34) 0%, transparent 50% 100%);
}

.RS-tile__inner{
  border-radius: inherit;
}

.RS-tile__content{
  padding: var(--RS-space-16);
  font-size: var(--RS-font-size-label-desktop);
  /* font-weight: var(--RS-font-weight-variable-bold); */
  color: var(--RS-white-color);
  z-index: 1;
  position: relative;
}

.RS-tile--sd-recommendation .RS-tile__content{
  line-height: var(--RS-line-height-lg);
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0% 67%, transparent 85% 100%);
}

.RS-tile--sd-recommendation .RS-tile__content > span{
  display: block;
  position: relative;
  font-weight: var(--RS-font-weight-variable-bold);
  margin: var(--RS-space-2) 0;
}

.RS-tile--sd-recommendation .RS-tile__content > span:before{
  content: '';
  display: block;
  position: absolute;
  left: var(--RS-space-4-n);
  top: var(--RS-space-2-n);
  width: calc(100% + var(--RS-space-4)*2);
  height: calc(100% + var(--RS-space-2)*2);
  background: var(--RS-black-color);
  z-index: -1;
  border-radius: var(--RS-space-4);
  opacity: .5;
  animation: animRecommendationPulse 1s linear infinite;
}

@keyframes animRecommendationPulse {
  0%, 100%{
    opacity: .5;
    /* transform: scale(1); */
  }
  50%{
    opacity: .34;
    /* transform: scale(.99); */
  }
}

.RS-tile--sd-churn .RS-tile__content{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-medium);
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0% 67%, transparent 93% 100%);

}

.RS-tile--sd-churn .RS-tile__content > span{
  padding: calc(var(--RS-space-12) - var(--RS-space-1)) var(--RS-space-6);
  border-right: var(--RS-space-1) solid rgba(1,6,14,.13);
  border-bottom: var(--RS-space-1) solid rgba(1,6,14,.13);
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(5n){
  border-right: unset;
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(1),
.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(2),
.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(3),
.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(4),
.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(5){
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(10),
.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(20){
  color: #188f12;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(15){
  color: #708f12;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(25){
  color: #8f3212;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(30),
.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(40){
  color: #8f8812;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(35){
  color: #8f7012;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(25){
animation: animChurnRate 1s linear infinite;
}

@keyframes animChurnRate {
  0%, 100%{
    /* opacity: 1; */
    transform: scale(1);
  }
  50%{
    /* opacity: .67; */
    transform: scale(.96);
  }
}


.RS-tile--nlp-reviews .RS-tile__content{
  height: 50%;
  color: var(--RS-color-black);
  font-weight: var(--RS-font-weight-variable-bold);
  line-height: var(--RS-line-height-md);
  padding: var(--RS-space-24);
}

.RS-tile--nlp-reviews .RS-tile__content:nth-of-type(even){
  padding-top: var(--RS-space-24);
}

.RS-tile--nlp-reviews .RS-tile__content:nth-of-type(even):before{
  content: '';
  display: block;
  width: 100%;
  height: var(--RS-space-1);
  margin: var(--RS-space-8) 0 var(--RS-space-12) 0;
  background-color: var(--RS-white-color);
}

.RS-tile--nlp-reviews .RS-tile__content > span{
  font-size: var(--RS-font-size-h2-desktop);
  line-height: 0;
  transform: translateY(var(--RS-space-8));
  display: inline-flex;
  margin-right: var(--RS-space-4);
}

.RS-tile--nlp-reviews .RS-tile__content .RS-tag{
  max-width: calc(var(--RS-space-128) - var(--RS-space-16));
  margin: var(--RS-space-6) 0;
}

.RS-tile--nlp-reviews .RS-tile__content:nth-of-type(1) .RS-tag span{
  color: #bcea4e;
}

.RS-tile--nlp-reviews .RS-tile__content:nth-of-type(2) .RS-tag span{
  color: #6fea4e;
}


.RS-tile--nlp-files .RS-tile__inner,
.RS-tile--nlp-files .RS-tile__content{
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.RS-tile--nlp-files .RS-tile__content svg{
  width: calc(var(--RS-space-32) - var(--RS-space-4));
}

.RS-tile--nlp-files .RS-tile__content > div{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(1){
  left: 37%;
  top: -17%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(2){
  left: 90%;
  top: -14%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(4){
  left: 67%;
  top: 10%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(5){
  left: -19%;
  top: 20%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(6){
  left: 30%;
  top: 25%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(7){
  left: 94%;
  top: 33%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(8){
  left: 5%;
  top: 47.5%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(9){
  left: 62%;
  top: 50%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(10){
  left: 33%;
  top: 65%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(11){
  left: 96%;
  top: 70%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(12){
  left: 3%;
  top: 84.5%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(13){
  left: 63%;
  top: 90.5%;
}

.RS-tile--nlp-files .RS-tile__content .RS-tag{
  background-color: rgba(1,6,14, .5);
  margin: var(--RS-space-6) 0 var(--RS-space-2) 0;
}

.RS-tile--nlp-files .RS-tile__content > div > span:last-of-type{
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  transform: scale(.8);
}




.RS-tile--a-song{
  background-image: radial-gradient(ellipse at right 30%, rgba(62,75,177,.34) 0%, transparent 50% 100%);
  overflow: visible;
  perspective: 500px;
  perspective-origin: 50% 50%;
}

.RS-tile--a-song:before{
  content: '';
  display: block;
  position: absolute;
  left: var(--RS-space-32);
  top: calc(var(--RS-space-32-n) - var(--RS-space-4) - var(--RS-space-1-n));
  width: var(--RS-space-128);
  height: var(--RS-space-48);
  background-color: inherit;
  border-radius: var(--RS-space-16) var(--RS-space-16) 0 0;
  transform: rotateX(45deg) skewX(6deg);
}



.RS-tile--a-voice:before{
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 150%;
  height: 150%;
  background-image: radial-gradient(ellipse at right bottom, var(--RS-a-color) 0% 21%, transparent 100%),  url('../../images/tiles/a-mouth.jpg');
  background-size: 100%, 75%;
  background-position: 0% 0%, 125% 125%;
  background-repeat: no-repeat;
}


.RS-tile--a-song .RS-tile__inner{
  display: flex;
}


.RS-tile--a-song .RS-tile__content svg{
  width: var(--RS-space-32);
  margin: 0 auto;
}

.RS-tile--a-song .RS-tile__content:first-of-type{
  border-right: var(--RS-space-1) solid rgba(1,6,14,.13);
  margin: var(--RS-space-16) 0;
}


.RS-tile--a-song .RS-tile__content:first-of-type > span:last-of-type{
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  color: var(--RS-black-color);
  margin: var(--RS-space-2) auto 0 auto;
  padding: var(--RS-space-12) auto;
  display: flex;
  opacity: .5;
}

.RS-tile--a-song .RS-tile__content:last-of-type{
  display: flex;
  flex-direction: column;
  gap: var(--RS-space-16);
}

.RS-tile--a-song .RS-tile__content:last-of-type > div{
  display: flex;
  flex-direction: column;
}

.RS-tile--a-song .RS-tile__content .RS-tag-title{
  text-transform: uppercase;
  display: flex;
  font-size: calc(var(--RS-font-size-disclaimer) * 0.8);
  opacity: .75;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  letter-spacing: 1px;
  margin-bottom: var(--RS-space-6);
}

.RS-tile--a-song .RS-tile__content:last-of-type > div:nth-of-type(2) > div{
  display: flex;
}

.RS-tile--a-song .RS-tile__content:last-of-type > div.RS-multi-tags{
  display: flex;
  flex-direction: row;
}

.RS-tile--a-song .RS-tile__content:last-of-type .RS-tag{
  background-color: var(--RS-a-color);
  margin-right: var(--RS-space-12);
  align-self: flex-start;
  display: inline-block;
}

.RS-tile--a-voice .RS-tile__inner{
  display: flex;
  width: 100%;
  height: 100%;
}


.RS-tile--a-voice .RS-tile__content{
  width: 50%;
  border: 1px solid var(--RS-white-color);
  /* padding: 0; */
  padding: var(--RS-space-8);
  margin: var(--RS-space-12) var(--RS-space-6) var(--RS-space-12) var(--RS-space-16);
  border-radius: var(--RS-space-6);
  display: flex;
  align-items: flex-end;
}

.RS-tile--a-voice .RS-tile__content:first-of-type{
  align-items: flex-start;
}

.RS-tile--a-voice .RS-tile__content:last-of-type{
  margin: var(--RS-space-12) var(--RS-space-16) var(--RS-space-12) var(--RS-space-6);
}

.RS-tile--a-voice .RS-tile__inner > svg{
  width: 100%;
  height: 100%;
  position: absolute;
  mix-blend-mode: overlay;
}



.RS-tile--ts-scooter .RS-photo--square,
.RS-tile--ts-corn .RS-photo--square{
  position: absolute;
}

.RS-tile--ts-scooter .RS-tile__inner{
  display: flex;
  width: 100%;
  height: 100%;
}

.RS-tile--ts-scooter .RS-tile__content{
  width: 50%;
  /* padding: 0; */
  padding: var(--RS-space-8);
  display: flex;
  flex-direction: column;
  gap: var(--RS-space-4);
}

.RS-tile--ts-scooter .RS-tile__content:first-of-type{
  align-items: flex-start;
  justify-content: flex-start;
  margin: var(--RS-space-48) 0 0 var(--RS-space-16);
}

.RS-tile--ts-scooter .RS-tile__content:last-of-type{
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 0 var(--RS-space-6) var(--RS-space-64);
}

.RS-tile--ts-scooter .RS-tile__content:first-of-type .RS-tag{
  background-color: rgba(209,0,0,.67);
}

.RS-tile--ts-scooter .RS-tile__content:last-of-type .RS-tag{
  background-color: rgba(209,84,0,.67);
}

.RS-tile--ts-scooter .RS-tile__content .RS-tag-title {
  text-transform: uppercase;
  font-size: calc(var(--RS-font-size-disclaimer) * .8);
  opacity: .5;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  letter-spacing: 1px;
}

.RS-tag-desc{
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-extra-bold);
}


.RS-dot-highlight{
  width: var(--RS-space-32);
  height: var(--RS-space-32);
  border-radius: 50%;
}

.RS-tile--ts-scooter .RS-tile__content:first-of-type .RS-dot-highlight{
  background-color: rgba(209,0,0,.67);
  position: absolute;
  top: -2.5%;
  right: -14.5%;
  animation: animScooterHighlight 2s linear infinite;
}



.RS-tile--ts-scooter .RS-tile__content:last-of-type .RS-dot-highlight{
  background-color: rgba(209,84,0,.67);
  position: absolute;
  bottom: 14.5%;
  left: -42.5%;
  animation: animScooterHighlight 2s linear infinite;
  animation-delay: .5s;
}

@keyframes animScooterHighlight {
  0%, 100%{
    transform: scale(1);
  }
  50%{
    transform: scale(.86);
  }
}


.RS-tile--ts-corn .RS-tag{
  display: flex;
  flex-direction: column;
  gap: var(--RS-space-4);
  background-color: rgba(1,6,14,.5);
  padding: var(--RS-space-8) var(--RS-space-16);
  position: absolute;
  bottom: 32%;
  left: 14%;
}

.RS-tile--ts-corn .RS-tag > span:nth-of-type(1){
  border-bottom: 1px solid rgba(1,6,14, .5);
  padding-bottom: var(--RS-space-6);
}

.RS-tile--ts-corn .RS-tag > span:nth-of-type(2){
  color: #9aef41;
}

.RS-tile--ts-corn .RS-tag > span:nth-of-type(3){
  font-weight: var(--RS-font-weight-variable-bold);
  font-size: calc(var(--RS-font-size-disclaimer) * 0.9);
}

.RS-bar-highlight{
  width: calc(var(--RS-space-2) + var(--RS-space-1));
  height: calc(var(--RS-space-24) + var(--RS-space-2));
}

.RS-tile--ts-corn .RS-bar-highlight{
  background-color: #9aef41;
  position: absolute;
  bottom: 18%;
  left: 29.5%;
  animation: animCornHighlight 1.5s linear infinite;
}

@keyframes animCornHighlight {
  0%, 100%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
}






.RS-tag{
  padding: var(--RS-space-6) var(--RS-space-8);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  color: var(--RS-white-color);
  border-radius: var(--RS-space-6);
  background: var(--RS-black-color);
  text-align: center;
  padding: var(--RS-space-6);
  font-size: var(--RS-font-size-label-desktop);
}

.RS-tag--semi-transparent{
  opacity: .67;
}

.RS-tag--inverted{
  background: var(--RS-white-color);
  color: var(--RS-black-color);
}

.RS-tag--cv{
  position: absolute;
}

/* .RS-tag--cv-dog{
  bottom: var(--RS-space-12);
  left: var(--RS-space-16);
}

.RS-tag--cv-dog > span{
  text-transform: capitalize;
}

.RS-tag--cv-dog__state{
  animation: animTagCvDogText 4.1s linear infinite;
  display: inline-block;
  animation-play-state: paused;
}

.RS-tag--cv-dog__state--playing{
  animation-play-state: running;
}

@keyframes animTagCvDogText {
  0%,75%{
    display: inline-block;
  }
  75.0001%,100%{
    display: none;
  }
} */

.RS-tag--cv-shelf-top{
  top: 11%;
  right: 30%;
  border-bottom-right-radius: 0;
}

.RS-tag--cv-shelf-mid{
  top: 50%;
  right: 12.5%;
  border-bottom-right-radius: 0;
}

.RS-tag--cv-shelf-bot{
  top: 60%;
  left: 12.5%;
  border-bottom-left-radius: 0;
}

.RS-tag--cv-shelf-top:before,
.RS-tag--cv-shelf-mid:before,
.RS-tag--cv-shelf-bot:before{
  content: '';
  position: absolute;
  width: 70%;
  height: 120%;
  border: var(--RS-space-2) solid var(--RS-white-color);
  border-radius: var(--RS-space-2);
  left: 100%;
  top: 100%;
  animation: animTagShelfOutline 1s linear infinite;
}

@keyframes animTagShelfOutline {
  0%, 100%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
}

.RS-tag--cv-shelf-mid:before{
  width: 25%;
}

.RS-tag--cv-shelf-bot:before{
  width: 33%;
  left: -33%;
  height: 200%;
}
/* 
.RS-tile__video--cv{
  mask-image: linear-gradient(220deg, rgba(0, 0, 0, 1), transparent 100%);
} */

.RS-tag--typing{
  color: var(--RS-white-color);
  background-color: rgba(1,6,14,.5);
  font-weight: var(--RS-font-weight-variable-medium);
}

.RS-tag--typing:after{
  content: '|';
  font-weight: var(--RS-font-weight-variable-regular);
  margin-left: var(--RS-space-2);
  animation: animTagTyping 1s linear infinite;
}

@keyframes animTagTyping {
  0%, 33%, 67%, 100%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
}

.RS-tag__icon{
  width: var(--RS-space-12);
  height: var(--RS-space-12);
  margin-left: var(--RS-space-6);
}

.RS-tile-group--settings .RS-tag{
  background-color: rgba(1,6,14, .5);
}


.RS-tile-group.RS-tile-group--features{
  display: flex;
  /* position: absolute; */
  height: var(--RS-space-256) + var(--RS-space-64);
  gap: var(--RS-space-32);
  justify-content: center;
  margin: var(--RS-space-32) 0;

  flex-direction: column;
  align-items: center;
  
  @media (--lg-viewport) {
    flex-direction: row;
    align-items: initial;
  }
}

.RS-tile-group--features .RS-tile,
.RS-tile-group--features .RS-tile:nth-of-type(2){
  position: relative;
  left: unset;
  top: unset;
  transform: none;
}

.RS-tile-group--features .RS-tile--nlp-reviews{
  text-align: left;
}

.RS-tile-group--features .RS-tile--nlp-reviews .RS-tile__content{
  height: 31.5%;
  padding: var(--RS-space-16) 0;
}

.RS-tile-group--features .RS-tile--nlp-reviews .RS-tile__inner{
  padding: 0 var(--RS-space-24);
}

.RS-tile-group--features .RS-tile--nlp-reviews .RS-tile__content:nth-of-type(2n):before{
  display: none;
}

.RS-tile-group--features .RS-tile--nlp-reviews .RS-tile__content .RS-tag{
  max-width: calc(var(--RS-space-128) - var(--RS-space-48));
}

.RS-tile-group--features .RS-tag--cv-shelf-top:before,
.RS-tile-group--features .RS-tag--cv-shelf-mid:before,
.RS-tile-group--features .RS-tag--cv-shelf-bot:before{
  animation: none;
}

.RS-tile-group--features .RS-tag--cv-shelf-top{
  top: 19%;
  right: 74%;
}

.RS-tile-group--features .RS-tag--cv-shelf-top:before{
  width: 354%;
  height: 380%;
}

.RS-tile-group--features .RS-tag--cv-shelf-mid{
  top: 60%;
  right: 43%;
}

.RS-tile-group--features .RS-tag--cv-shelf-mid:before{
  width: 120%;
  height: 380%;
}

.RS-tile-group--features .RS-tag--cv-shelf-bot{
  top: 60%;
  left: 5%;
}

.RS-tile-group--features .RS-tag--cv-shelf-bot:before{
  width: 138%;
  height: 380%;
}

.RS-tile-group--settings .RS-tile{
  background-color: var(--RS-white-color);
  box-shadow: var(--RS-box-shadow);
}

.RS-tile-group--settings .RS-tile:nth-of-type(2){
  margin: var(--RS-space-32) var(--RS-space-32) 0 var(--RS-space-32);
}

.RS-tile-group--settings .RS-tile__inner{
  width: 100%;
}

.RS-tile-group--settings .RS-tile .RS-tile__content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-tile-group--settings .RS-tile .RS-tile__content > span:first-of-type{
  font-size: calc(var(--RS-font-size-disclaimer) * .92);
}

.RS-tile-group--settings .RS-tile .RS-tile__content > span:first-of-type:after{
  content: '';
  display: block;
  width: 50%;
  height: var(--RS-space-1);
  background-color: rgba(1,6,14,.13);
  margin-top: var(--RS-space-12);
}

.RS-tile-group--settings .RS-tile .RS-tile__content > div{
  display: flex;
  align-items: center;
  margin-top: var(--RS-space-12);
}

.RS-tile-group--settings .RS-tile .RS-tag{
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
}

.RS-tile-group--settings .RS-button{
  background-image: unset;
  box-shadow: unset;
  padding: var(--RS-space-12);
  display: inline-flex;
  margin-left: auto;
  margin-top: var(--RS-space-32)!important;
}

.RS-tile-group--settings .RS-tile--cv .RS-button{
  background-color: var(--RS-cv-color);
}

.RS-tile-group--settings .RS-tile--nlp .RS-button{
  background-color: var(--RS-nlp-color);
}

.RS-tile-group--settings .RS-tile--sd .RS-button{
  background-color: var(--RS-sd-color);
}

.RS-tile-group--settings .RS-tile--a .RS-button{
  background-color: var(--RS-a-color);
}

.RS-tile-group--settings .RS-tile--cv .RS-tile__content > span:first-of-type{
  color: var(--RS-cv-color);
}

.RS-tile-group--settings .RS-tile--nlp .RS-tile__content > span:first-of-type{
  color: var(--RS-nlp-color);
}

.RS-tile-group--settings .RS-tile--sd .RS-tile__content > span:first-of-type{
  color: var(--RS-sd-color);
}

.RS-tile-group--settings .RS-tile--a .RS-tile__content > span:first-of-type{
  color: var(--RS-a-color);
}

.RS-tile-group--settings .RS-tile--a{
  height: auto;
}

.RS-tile-group--settings .RS-tile--a .RS-tile__content{
  gap: var(--RS-space-8);
}

.RS-tile-group--settings .RS-tile--a .RS-tile__content > span{
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-tile-group--settings .RS-tile--a .RS-tile__content > span:nth-of-type(3){
  font-size: calc(var(--RS-font-size-h1-desktop) * 0.6);
  font-weight: var(--RS-font-weight-variable-extra-bold);
}


.RS-tile-group--settings .RS-tile--a .RS-button{
  margin-left: unset;
}

.RS-tile-group--settings .RS-tile--cv .RS-button,
.RS-tile-group--settings .RS-tile--nlp .RS-button,
.RS-tile-group--settings .RS-tile--sd .RS-button{
  animation: animButtonClick 4s var(--RS-default-transition) infinite;
}

.RS-tile-group--settings .RS-tile--nlp .RS-button{
  animation-delay: .3s;
}

.RS-tile-group--settings .RS-tile--sd .RS-button{
  animation-delay: .6s;
}

@keyframes animButtonClick {
  0%, 5%, 15%, 100%{
    transform: scale(1);
  }
  10%{
    transform: scale(.96);
  }
}
