

.RS-pricing-table {
  padding: var(--RS-space-64) 0;
  flex-direction: column;
  display: flex;
}

.RS-pricing-table__heading, .RS-pricing-table__content, .RS-pricing-table__buttons {
  display: flex;
  position: relative;
}

.RS-pricing-table__heading:after, .RS-pricing-table__content:not(:nth-of-type(4)):after {
  content: "";
  width: 100%;
  bottom: var(--RS-space-2-n);
  height: var(--RS-space-2);
  box-shadow: var(--RS-box-shadow);
  background-color: #fff;
  display: block;
  position: absolute;
}

.RS-pricing-table__content > div:first-of-type {
  flex-direction: column;
  display: flex;
}

.RS-pricing-table__heading > div, .RS-pricing-table__content > div, .RS-pricing-table__buttons > div {
  padding: var(--RS-space-16) 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  :is(.RS-pricing-table__heading > div, .RS-pricing-table__content > div, .RS-pricing-table__buttons > div) {
    padding: var(--RS-space-24);
  }
}

.RS-pricing-table__heading > div:first-of-type, .RS-pricing-table__content > div:first-of-type, .RS-pricing-table__buttons > div:first-of-type {
  width: 25%;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (min-width: 480px) {
  :is(.RS-pricing-table__heading > div:first-of-type, .RS-pricing-table__content > div:first-of-type, .RS-pricing-table__buttons > div:first-of-type) {
    width: 30%;
  }
}

.RS-pricing-table__heading > div:not(:first-of-type), .RS-pricing-table__content > div:not(:first-of-type), .RS-pricing-table__buttons > div:not(:first-of-type) {
  width: 25%;
}

@media (min-width: 480px) {
  :is(.RS-pricing-table__heading > div:not(:first-of-type), .RS-pricing-table__content > div:not(:first-of-type), .RS-pricing-table__buttons > div:not(:first-of-type)) {
    width: 23.33%;
  }
}

.RS-pricing-table__heading > div:not(:first-of-type) {
  position: relative;
}

.RS-pricing-table__heading > div:not(:first-of-type):before {
  content: "";
  width: 80%;
  height: 100%;
  border-radius: var(--RS-space-16) var(--RS-space-16) 0 0;
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 10%;
}

@media (min-width: 480px) {
  .RS-pricing-table__heading > div:not(:first-of-type):before {
    background-color: #fff;
  }
}

.RS-pricing-table__check {
  background-color: var(--RS-black-color);
  width: var(--RS-space-24);
  height: var(--RS-space-24);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .RS-pricing-table__check {
    width: var(--RS-space-48);
    height: var(--RS-space-48);
  }
}

.RS-pricing-table__icon {
  width: var(--RS-space-12);
  height: var(--RS-space-12);
}

@media (min-width: 1024px) {
  .RS-pricing-table__icon {
    width: var(--RS-space-16);
    height: var(--RS-space-16);
  }
}

.RS-pricing-table__heading, .RS-pricing-table__content > div > span:first-of-type {
  font-size: var(--RS-font-size-label);
  font-weight: var(--RS-font-weight-variable-bold);
}

@media (min-width: 480px) {
  :is(.RS-pricing-table__heading, .RS-pricing-table__content > div > span:first-of-type) {
    font-size: var(--RS-font-size-h3);
  }
}

@media (min-width: 1024px) {
  :is(.RS-pricing-table__heading, .RS-pricing-table__content > div > span:first-of-type) {
    font-size: var(--RS-font-size-h3-desktop);
  }
}

.RS-pricing-table__content > div > span:last-of-type {
  margin-top: var(--RS-space-8);
  font-size: var(--RS-font-size-label);
  font-weight: var(--RS-font-weight-variable-medium);
}

@media (min-width: 1024px) {
  .RS-pricing-table__content > div > span:last-of-type {
    font-size: var(--RS-font-size-label-desktop);
  }
}

.RS-pricing-table__buttons .RS-button {
  padding: var(--RS-space-16) var(--RS-space-24);
  margin-top: var(--RS-space-8);
}

.RS-pricing-table__buttons .RS-button {
  font-size: var(--RS-font-size-label);
  text-align: center;
  max-width: calc(100% - var(--RS-space-8));
  padding: var(--RS-space-6) var(--RS-space-6);
}

@media (min-width: 480px) {
  .RS-pricing-table__buttons .RS-button {
    padding: var(--RS-space-12) var(--RS-space-6);
  }
}

@media (min-width: 1024px) {
  .RS-pricing-table__buttons .RS-button {
    font-size: inherit;
  }
}

.RS-intro__tile-wrapper {
  justify-content: flex-end;
  gap: var(--RS-space-32);
  width: 100%;
  margin-top: var(--RS-space-96);
  flex-direction: column-reverse;
  display: flex;
}

@media (min-width: 1024px) {
  .RS-intro__tile-wrapper {
    gap: unset;
    margin-top: auto;
    margin-bottom: calc(var(--RS-space-32)  + var(--RS-space-8));
    flex-direction: row;
  }
}

.RS-tile-slider {
  position: relative;
}

@media (min-width: 1024px) {
  .RS-tile-slider {
    transform: none;
  }
}

.RS-intro__tile-slider-group {
  transition: opacity var(--RS-anim-duration-md) linear, transform var(--RS-anim-duration-md) var(--RS-default-transition);
  flex-direction: row-reverse;
  justify-content: flex-start;
  display: none;
  position: absolute;
}

@media (min-width: 1024px) {
  .RS-intro__tile-slider-group {
    justify-content: unset;
    margin-top: unset;
    right: unset;
    flex-direction: row;
    position: relative;
  }
}

.RS-intro__tile-slider-group:first-of-type {
  opacity: 1;
  display: flex;
}

.RS-tile {
  width: calc(var(--RS-space-256)  + var(--RS-space-64));
  height: calc(var(--RS-space-256)  + var(--RS-space-64));
  border-radius: calc(var(--RS-space-8)  + var(--RS-space-2));
  background-color: var(--RS-light-grey-color);
  box-shadow: var(--RS-box-shadow-alt);
  transform: translateX(var(--RS-space-16));
  transition: opacity .75s linear, transform .75s var(--RS-default-transition);
  display: flex;
  position: relative;
  overflow: hidden;
}

.RS-intro__tile-slider-group .RS-tile:nth-of-type(2) {
  z-index: -1;
  box-shadow: unset;
  transform: translateX(var(--RS-space-16-n));
  margin-right: var(--RS-space-128-n);
  margin-top: var(--RS-space-48-n);
}

@media (min-width: 640px) {
  .RS-intro__tile-slider-group .RS-tile:nth-of-type(2) {
    margin-right: var(--RS-space-48-n);
  }
}

@media (min-width: 1024px) {
  .RS-intro__tile-slider-group .RS-tile:nth-of-type(2) {
    margin: unset;
    position: absolute;
    top: -20%;
    left: -84%;
  }
}

.RS-tile-group.RS-is-visible .RS-tile {
  transform: none;
}

.RS-tile--cv-dog {
  background-color: #e3d6d3;
}

.RS-tile--nlp-files:before {
  content: "";
  width: 150%;
  height: 150%;
  background-image: radial-gradient(ellipse at left top, var(--RS-nlp-color) 0% 34%, transparent 100%);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.RS-tile--nlp-reviews {
  background-image: radial-gradient(at 0 0, #c42d1957 0%, #0000 50% 100%);
}

.RS-tile--sd-recommendation:before {
  content: "";
  width: 150%;
  height: 150%;
  background-image: radial-gradient(ellipse at left bottom, var(--RS-sd-color) 0% 34%, transparent 100%), url("bg-json.96c97296.svg");
  background-repeat: no-repeat;
  background-size: 150%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.RS-tile--sd-churn {
  background-image: radial-gradient(circle at 0%, #9a3eb157 0%, #0000 50% 100%);
}

.RS-tile__inner {
  border-radius: inherit;
}

.RS-tile__content {
  padding: var(--RS-space-16);
  font-size: var(--RS-font-size-label-desktop);
  color: var(--RS-white-color);
  z-index: 1;
  position: relative;
}

.RS-tile--sd-recommendation .RS-tile__content {
  line-height: var(--RS-line-height-lg);
  -webkit-mask-image: linear-gradient(#000 0% 67%, #0000 85% 100%);
  mask-image: linear-gradient(#000 0% 67%, #0000 85% 100%);
}

.RS-tile--sd-recommendation .RS-tile__content > span {
  font-weight: var(--RS-font-weight-variable-bold);
  margin: var(--RS-space-2) 0;
  display: block;
  position: relative;
}

.RS-tile--sd-recommendation .RS-tile__content > span:before {
  content: "";
  left: var(--RS-space-4-n);
  top: var(--RS-space-2-n);
  width: calc(100% + var(--RS-space-4) * 2);
  height: calc(100% + var(--RS-space-2) * 2);
  background: var(--RS-black-color);
  z-index: -1;
  border-radius: var(--RS-space-4);
  opacity: .5;
  animation: 1s linear infinite animRecommendationPulse;
  display: block;
  position: absolute;
}

@keyframes animRecommendationPulse {
  0%, 100% {
    opacity: .5;
  }

  50% {
    opacity: .34;
  }
}

.RS-tile--sd-churn .RS-tile__content {
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-medium);
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  -webkit-mask-image: linear-gradient(#000 0% 67%, #0000 93% 100%);
  mask-image: linear-gradient(#000 0% 67%, #0000 93% 100%);
}

.RS-tile--sd-churn .RS-tile__content > span {
  padding: calc(var(--RS-space-12)  - var(--RS-space-1)) var(--RS-space-6);
  border-right: var(--RS-space-1) solid #01060e21;
  border-bottom: var(--RS-space-1) solid #01060e21;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(5n) {
  border-right: unset;
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-tile--sd-churn .RS-tile__content > span:first-of-type, .RS-tile--sd-churn .RS-tile__content > span:nth-of-type(2), .RS-tile--sd-churn .RS-tile__content > span:nth-of-type(3), .RS-tile--sd-churn .RS-tile__content > span:nth-of-type(4), .RS-tile--sd-churn .RS-tile__content > span:nth-of-type(5) {
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(10), .RS-tile--sd-churn .RS-tile__content > span:nth-of-type(20) {
  color: #188f12;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(15) {
  color: #708f12;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(25) {
  color: #8f3212;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(30), .RS-tile--sd-churn .RS-tile__content > span:nth-of-type(40) {
  color: #8f8812;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(35) {
  color: #8f7012;
}

.RS-tile--sd-churn .RS-tile__content > span:nth-of-type(25) {
  animation: 1s linear infinite animChurnRate;
}

@keyframes animChurnRate {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(.96);
  }
}

.RS-tile--nlp-reviews .RS-tile__content {
  height: 50%;
  color: var(--RS-color-black);
  font-weight: var(--RS-font-weight-variable-bold);
  line-height: var(--RS-line-height-md);
  padding: var(--RS-space-24);
}

.RS-tile--nlp-reviews .RS-tile__content:nth-of-type(2n) {
  padding-top: var(--RS-space-24);
}

.RS-tile--nlp-reviews .RS-tile__content:nth-of-type(2n):before {
  content: "";
  width: 100%;
  height: var(--RS-space-1);
  margin: var(--RS-space-8) 0 var(--RS-space-12) 0;
  background-color: var(--RS-white-color);
  display: block;
}

.RS-tile--nlp-reviews .RS-tile__content > span {
  font-size: var(--RS-font-size-h2-desktop);
  transform: translateY(var(--RS-space-8));
  margin-right: var(--RS-space-4);
  line-height: 0;
  display: inline-flex;
}

.RS-tile--nlp-reviews .RS-tile__content .RS-tag {
  max-width: calc(var(--RS-space-128)  - var(--RS-space-16));
  margin: var(--RS-space-6) 0;
}

.RS-tile--nlp-reviews .RS-tile__content:first-of-type .RS-tag span {
  color: #bcea4e;
}

.RS-tile--nlp-reviews .RS-tile__content:nth-of-type(2) .RS-tag span {
  color: #6fea4e;
}

.RS-tile--nlp-files .RS-tile__inner, .RS-tile--nlp-files .RS-tile__content {
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.RS-tile--nlp-files .RS-tile__content svg {
  width: calc(var(--RS-space-32)  - var(--RS-space-4));
}

.RS-tile--nlp-files .RS-tile__content > div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.RS-tile--nlp-files .RS-tile__content > div:first-of-type {
  top: -17%;
  left: 37%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(2) {
  top: -14%;
  left: 90%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(4) {
  top: 10%;
  left: 67%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(5) {
  top: 20%;
  left: -19%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(6) {
  top: 25%;
  left: 30%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(7) {
  top: 33%;
  left: 94%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(8) {
  top: 47.5%;
  left: 5%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(9) {
  top: 50%;
  left: 62%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(10) {
  top: 65%;
  left: 33%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(11) {
  top: 70%;
  left: 96%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(12) {
  top: 84.5%;
  left: 3%;
}

.RS-tile--nlp-files .RS-tile__content > div:nth-of-type(13) {
  top: 90.5%;
  left: 63%;
}

.RS-tile--nlp-files .RS-tile__content .RS-tag {
  margin: var(--RS-space-6) 0 var(--RS-space-2) 0;
  background-color: #01060e80;
}

.RS-tile--nlp-files .RS-tile__content > div > span:last-of-type {
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  transform: scale(.8);
}

.RS-tile--a-song {
  perspective: 500px;
  perspective-origin: 50%;
  background-image: radial-gradient(at right 30%, #3e4bb157 0%, #0000 50% 100%);
  overflow: visible;
}

.RS-tile--a-song:before {
  content: "";
  left: var(--RS-space-32);
  top: calc(var(--RS-space-32-n)  - var(--RS-space-4)  - var(--RS-space-1-n));
  width: var(--RS-space-128);
  height: var(--RS-space-48);
  background-color: inherit;
  border-radius: var(--RS-space-16) var(--RS-space-16) 0 0;
  display: block;
  position: absolute;
  transform: rotateX(45deg)skewX(6deg);
}

.RS-tile--a-voice:before {
  content: "";
  width: 150%;
  height: 150%;
  background-image: radial-gradient(ellipse at right bottom, var(--RS-a-color) 0% 21%, transparent 100%), url("a-mouth.ddef4801.jpg");
  background-position: 0 0, 125% 125%;
  background-repeat: no-repeat;
  background-size: 100%, 75%;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.RS-tile--a-song .RS-tile__inner {
  display: flex;
}

.RS-tile--a-song .RS-tile__content svg {
  width: var(--RS-space-32);
  margin: 0 auto;
}

.RS-tile--a-song .RS-tile__content:first-of-type {
  border-right: var(--RS-space-1) solid #01060e21;
  margin: var(--RS-space-16) 0;
}

.RS-tile--a-song .RS-tile__content:first-of-type > span:last-of-type {
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  color: var(--RS-black-color);
  margin: var(--RS-space-2) auto 0 auto;
  padding: var(--RS-space-12) auto;
  opacity: .5;
  display: flex;
}

.RS-tile--a-song .RS-tile__content:last-of-type {
  gap: var(--RS-space-16);
  flex-direction: column;
  display: flex;
}

.RS-tile--a-song .RS-tile__content:last-of-type > div {
  flex-direction: column;
  display: flex;
}

.RS-tile--a-song .RS-tile__content .RS-tag-title {
  text-transform: uppercase;
  font-size: calc(var(--RS-font-size-disclaimer) * .8);
  opacity: .75;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  letter-spacing: 1px;
  margin-bottom: var(--RS-space-6);
  display: flex;
}

.RS-tile--a-song .RS-tile__content:last-of-type > div:nth-of-type(2) > div {
  display: flex;
}

.RS-tile--a-song .RS-tile__content:last-of-type > div.RS-multi-tags {
  flex-direction: row;
  display: flex;
}

.RS-tile--a-song .RS-tile__content:last-of-type .RS-tag {
  background-color: var(--RS-a-color);
  margin-right: var(--RS-space-12);
  align-self: flex-start;
  display: inline-block;
}

.RS-tile--a-voice .RS-tile__inner {
  width: 100%;
  height: 100%;
  display: flex;
}

.RS-tile--a-voice .RS-tile__content {
  width: 50%;
  border: 1px solid var(--RS-white-color);
  padding: var(--RS-space-8);
  margin: var(--RS-space-12) var(--RS-space-6) var(--RS-space-12) var(--RS-space-16);
  border-radius: var(--RS-space-6);
  align-items: flex-end;
  display: flex;
}

.RS-tile--a-voice .RS-tile__content:first-of-type {
  align-items: flex-start;
}

.RS-tile--a-voice .RS-tile__content:last-of-type {
  margin: var(--RS-space-12) var(--RS-space-16) var(--RS-space-12) var(--RS-space-6);
}

.RS-tile--a-voice .RS-tile__inner > svg {
  width: 100%;
  height: 100%;
  mix-blend-mode: overlay;
  position: absolute;
}

.RS-tile--ts-scooter .RS-photo--square, .RS-tile--ts-corn .RS-photo--square {
  position: absolute;
}

.RS-tile--ts-scooter .RS-tile__inner {
  width: 100%;
  height: 100%;
  display: flex;
}

.RS-tile--ts-scooter .RS-tile__content {
  width: 50%;
  padding: var(--RS-space-8);
  gap: var(--RS-space-4);
  flex-direction: column;
  display: flex;
}

.RS-tile--ts-scooter .RS-tile__content:first-of-type {
  margin: var(--RS-space-48) 0 0 var(--RS-space-16);
  justify-content: flex-start;
  align-items: flex-start;
}

.RS-tile--ts-scooter .RS-tile__content:last-of-type {
  margin: 0 0 var(--RS-space-6) var(--RS-space-64);
  justify-content: flex-end;
  align-items: flex-start;
}

.RS-tile--ts-scooter .RS-tile__content:first-of-type .RS-tag {
  background-color: #d10000ab;
}

.RS-tile--ts-scooter .RS-tile__content:last-of-type .RS-tag {
  background-color: #d15400ab;
}

.RS-tile--ts-scooter .RS-tile__content .RS-tag-title {
  text-transform: uppercase;
  font-size: calc(var(--RS-font-size-disclaimer) * .8);
  opacity: .5;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  letter-spacing: 1px;
}

.RS-tag-desc {
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-dot-highlight {
  width: var(--RS-space-32);
  height: var(--RS-space-32);
  border-radius: 50%;
}

.RS-tile--ts-scooter .RS-tile__content:first-of-type .RS-dot-highlight {
  background-color: #d10000ab;
  animation: 2s linear infinite animScooterHighlight;
  position: absolute;
  top: -2.5%;
  right: -14.5%;
}

.RS-tile--ts-scooter .RS-tile__content:last-of-type .RS-dot-highlight {
  background-color: #d15400ab;
  animation: 2s linear .5s infinite animScooterHighlight;
  position: absolute;
  bottom: 14.5%;
  left: -42.5%;
}

@keyframes animScooterHighlight {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(.86);
  }
}

.RS-tile--ts-corn .RS-tag {
  gap: var(--RS-space-4);
  padding: var(--RS-space-8) var(--RS-space-16);
  background-color: #01060e80;
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 32%;
  left: 14%;
}

.RS-tile--ts-corn .RS-tag > span:first-of-type {
  padding-bottom: var(--RS-space-6);
  border-bottom: 1px solid #01060e80;
}

.RS-tile--ts-corn .RS-tag > span:nth-of-type(2) {
  color: #9aef41;
}

.RS-tile--ts-corn .RS-tag > span:nth-of-type(3) {
  font-weight: var(--RS-font-weight-variable-bold);
  font-size: calc(var(--RS-font-size-disclaimer) * .9);
}

.RS-bar-highlight {
  width: calc(var(--RS-space-2)  + var(--RS-space-1));
  height: calc(var(--RS-space-24)  + var(--RS-space-2));
}

.RS-tile--ts-corn .RS-bar-highlight {
  background-color: #9aef41;
  animation: 1.5s linear infinite animCornHighlight;
  position: absolute;
  bottom: 18%;
  left: 29.5%;
}

@keyframes animCornHighlight {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.RS-tag {
  padding: var(--RS-space-6) var(--RS-space-8);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  color: var(--RS-white-color);
  border-radius: var(--RS-space-6);
  background: var(--RS-black-color);
  text-align: center;
  padding: var(--RS-space-6);
  font-size: var(--RS-font-size-label-desktop);
}

.RS-tag--semi-transparent {
  opacity: .67;
}

.RS-tag--inverted {
  background: var(--RS-white-color);
  color: var(--RS-black-color);
}

.RS-tag--cv {
  position: absolute;
}

.RS-tag--cv-shelf-top {
  border-bottom-right-radius: 0;
  top: 11%;
  right: 30%;
}

.RS-tag--cv-shelf-mid {
  border-bottom-right-radius: 0;
  top: 50%;
  right: 12.5%;
}

.RS-tag--cv-shelf-bot {
  border-bottom-left-radius: 0;
  top: 60%;
  left: 12.5%;
}

.RS-tag--cv-shelf-top:before, .RS-tag--cv-shelf-mid:before, .RS-tag--cv-shelf-bot:before {
  content: "";
  width: 70%;
  height: 120%;
  border: var(--RS-space-2) solid var(--RS-white-color);
  border-radius: var(--RS-space-2);
  animation: 1s linear infinite animTagShelfOutline;
  position: absolute;
  top: 100%;
  left: 100%;
}

@keyframes animTagShelfOutline {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.RS-tag--cv-shelf-mid:before {
  width: 25%;
}

.RS-tag--cv-shelf-bot:before {
  width: 33%;
  height: 200%;
  left: -33%;
}

.RS-tag--typing {
  color: var(--RS-white-color);
  font-weight: var(--RS-font-weight-variable-medium);
  background-color: #01060e80;
}

.RS-tag--typing:after {
  content: "|";
  font-weight: var(--RS-font-weight-variable-regular);
  margin-left: var(--RS-space-2);
  animation: 1s linear infinite animTagTyping;
}

@keyframes animTagTyping {
  0%, 33%, 67%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.RS-tag__icon {
  width: var(--RS-space-12);
  height: var(--RS-space-12);
  margin-left: var(--RS-space-6);
}

.RS-tile-group--settings .RS-tag {
  background-color: #01060e80;
}

.RS-tile-group.RS-tile-group--features {
  height: var(--RS-space-256)  + var(--RS-space-64);
  gap: var(--RS-space-32);
  margin: var(--RS-space-32) 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .RS-tile-group.RS-tile-group--features {
    align-items: initial;
    flex-direction: row;
  }
}

.RS-tile-group--features .RS-tile, .RS-tile-group--features .RS-tile:nth-of-type(2) {
  left: unset;
  top: unset;
  position: relative;
  transform: none;
}

.RS-tile-group--features .RS-tile--nlp-reviews {
  text-align: left;
}

.RS-tile-group--features .RS-tile--nlp-reviews .RS-tile__content {
  height: 31.5%;
  padding: var(--RS-space-16) 0;
}

.RS-tile-group--features .RS-tile--nlp-reviews .RS-tile__inner {
  padding: 0 var(--RS-space-24);
}

.RS-tile-group--features .RS-tile--nlp-reviews .RS-tile__content:nth-of-type(2n):before {
  display: none;
}

.RS-tile-group--features .RS-tile--nlp-reviews .RS-tile__content .RS-tag {
  max-width: calc(var(--RS-space-128)  - var(--RS-space-48));
}

.RS-tile-group--features .RS-tag--cv-shelf-top:before, .RS-tile-group--features .RS-tag--cv-shelf-mid:before, .RS-tile-group--features .RS-tag--cv-shelf-bot:before {
  animation: none;
}

.RS-tile-group--features .RS-tag--cv-shelf-top {
  top: 19%;
  right: 74%;
}

.RS-tile-group--features .RS-tag--cv-shelf-top:before {
  width: 354%;
  height: 380%;
}

.RS-tile-group--features .RS-tag--cv-shelf-mid {
  top: 60%;
  right: 43%;
}

.RS-tile-group--features .RS-tag--cv-shelf-mid:before {
  width: 120%;
  height: 380%;
}

.RS-tile-group--features .RS-tag--cv-shelf-bot {
  top: 60%;
  left: 5%;
}

.RS-tile-group--features .RS-tag--cv-shelf-bot:before {
  width: 138%;
  height: 380%;
}

.RS-tile-group--settings .RS-tile {
  background-color: var(--RS-white-color);
  box-shadow: var(--RS-box-shadow);
}

.RS-tile-group--settings .RS-tile:nth-of-type(2) {
  margin: var(--RS-space-32) var(--RS-space-32) 0 var(--RS-space-32);
}

.RS-tile-group--settings .RS-tile__inner {
  width: 100%;
}

.RS-tile-group--settings .RS-tile .RS-tile__content {
  text-align: left;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-extra-bold);
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.RS-tile-group--settings .RS-tile .RS-tile__content > span:first-of-type {
  font-size: calc(var(--RS-font-size-disclaimer) * .92);
}

.RS-tile-group--settings .RS-tile .RS-tile__content > span:first-of-type:after {
  content: "";
  width: 50%;
  height: var(--RS-space-1);
  margin-top: var(--RS-space-12);
  background-color: #01060e21;
  display: block;
}

.RS-tile-group--settings .RS-tile .RS-tile__content > div {
  margin-top: var(--RS-space-12);
  align-items: center;
  display: flex;
}

.RS-tile-group--settings .RS-tile .RS-tag {
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
  display: inline-flex;
}

.RS-tile-group--settings .RS-button {
  background-image: unset;
  box-shadow: unset;
  padding: var(--RS-space-12);
  margin-left: auto;
  display: inline-flex;
  margin-top: var(--RS-space-32) !important;
}

.RS-tile-group--settings .RS-tile--cv .RS-button {
  background-color: var(--RS-cv-color);
}

.RS-tile-group--settings .RS-tile--nlp .RS-button {
  background-color: var(--RS-nlp-color);
}

.RS-tile-group--settings .RS-tile--sd .RS-button {
  background-color: var(--RS-sd-color);
}

.RS-tile-group--settings .RS-tile--a .RS-button {
  background-color: var(--RS-a-color);
}

.RS-tile-group--settings .RS-tile--cv .RS-tile__content > span:first-of-type {
  color: var(--RS-cv-color);
}

.RS-tile-group--settings .RS-tile--nlp .RS-tile__content > span:first-of-type {
  color: var(--RS-nlp-color);
}

.RS-tile-group--settings .RS-tile--sd .RS-tile__content > span:first-of-type {
  color: var(--RS-sd-color);
}

.RS-tile-group--settings .RS-tile--a .RS-tile__content > span:first-of-type {
  color: var(--RS-a-color);
}

.RS-tile-group--settings .RS-tile--a {
  height: auto;
}

.RS-tile-group--settings .RS-tile--a .RS-tile__content {
  gap: var(--RS-space-8);
}

.RS-tile-group--settings .RS-tile--a .RS-tile__content > span {
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-tile-group--settings .RS-tile--a .RS-tile__content > span:nth-of-type(3) {
  font-size: calc(var(--RS-font-size-h1-desktop) * .6);
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-tile-group--settings .RS-tile--a .RS-button {
  margin-left: unset;
}

.RS-tile-group--settings .RS-tile--cv .RS-button, .RS-tile-group--settings .RS-tile--nlp .RS-button, .RS-tile-group--settings .RS-tile--sd .RS-button {
  animation: animButtonClick 4s var(--RS-default-transition) infinite;
}

.RS-tile-group--settings .RS-tile--nlp .RS-button {
  animation-delay: .3s;
}

.RS-tile-group--settings .RS-tile--sd .RS-button {
  animation-delay: .6s;
}

@keyframes animButtonClick {
  0%, 5%, 15%, 100% {
    transform: scale(1);
  }

  10% {
    transform: scale(.96);
  }
}

.RS-faq {
  width: 100%;
  padding: unset;
  flex-direction: column;
  display: flex;
}

.RS-faq__single {
  gap: var(--RS-space-16);
  -webkit-tap-highlight-color: transparent;
  flex-direction: column;
  display: flex;
  position: relative;
}

.RS-faq__single:after {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  background-color: var(--RS-light-grey-color);
  border-radius: var(--RS-space-2);
  bottom: calc(0px + var(--RS-space-1-n));
  position: absolute;
}

.RS-faq__single-question {
  cursor: pointer;
  padding: var(--RS-space-24) var(--RS-space-8);
  -webkit-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .RS-faq__single-question {
    padding: var(--RS-space-24);
  }
}

.RS-faq__single-answer {
  gap: var(--RS-space-16);
  padding: 0 var(--RS-space-8) var(--RS-space-32) var(--RS-space-8);
  flex-direction: column;
  display: none;
}

@media (min-width: 768px) {
  .RS-faq__single-answer {
    padding: 0 var(--RS-space-32) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-faq__single-answer--expanded {
  display: flex;
}

.RS-faq__single-answer p {
  line-height: var(--RS-line-height-xlg);
  font-size: var(--RS-font-size-p-desktop);
}

.RS-faq-icon {
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  margin-right: unset;
  transform: translateY(var(--RS-space-8));
}

@media (min-width: 768px) {
  .RS-faq-icon {
    margin-right: var(--RS-space-16);
  }
}

.RS-faq-icon--expanded {
  transform: translateY(var(--RS-space-8)) rotateZ(180deg);
}



/*# sourceMappingURL=index.c24549c3.css.map */
